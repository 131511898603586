import { configureStore, AnyAction, applyMiddleware } from '@reduxjs/toolkit';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { filesSlice, globalSlice } from './common/index';

export const reducers = {
  files: filesSlice.reducer,
  global: globalSlice.reducer,
};

export const store = configureStore({
  reducer: reducers,
  enhancers: [applyMiddleware(...[thunkMiddleware])],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type Selector<S> = (state: RootState) => S;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, AnyAction>;
