import React from 'react';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { Box, Typography } from '@mui/material';

const Footer = (): JSX.Element => {
  const { breakpoint } = useBreakpoint();

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: (theme) => theme.custom.footerHeights[breakpoint],
        height: (theme) => theme.custom.footerHeights[breakpoint],
        backgroundColor: (theme) => theme.palette.background.default,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 102,
        mt: 5,
      }}
    >
      <Typography variant="paragraphSmall" sx={{ color: (theme) => theme.palette.common.white }}>
        Ryerson - Metal Reader
      </Typography>
    </Box>
  );
};

export default Footer;
