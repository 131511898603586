import React from 'react';
import { Box } from '@mui/material';

const Overlay = (): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 100,
      }}
    />
  );
};

export default Overlay;
