import { Breakpoint, TypographyPropsVariantOverrides } from '@mui/material';
import { PaletteOptions, ThemeOptions } from '@mui/material/styles';
import { BreakpointsOptions } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

const spacing = 8;

const headerHeights = {
  xs: 50,
  sm: 50,
  md: 50,
  lg: 50,
  xl: 50,
};

const footerHeights = {
  xs: 100,
  sm: 100,
  md: 100,
  lg: 64,
  xl: 53,
};

const breakPointValues: { [key in Breakpoint]: number } = {
  xs: 0, // small mobile
  sm: 375, // large mobile
  md: 414, // large mobile
  lg: 744, // tablet
  xl: 1184, // desktop
};

const breakPointsOptions: BreakpointsOptions = { values: breakPointValues };

const lineHeights: { [key in OverridableStringUnion<Variant, TypographyPropsVariantOverrides>]: number } = {
  h1: 32,
  h2: 24,
  h3: 30,
  h4: 24,
  h5: 14,
  h6: 18,
  paragraph: 18,
  paragraphBold: 18,
  paragraphLink: 18,
  paragraphLarge: 18,
  paragraphSmall: 16,
  paragraphSmallBold: 14,
  paragraphSmallLink: 16,

  numberedList: 18,

  mobileParagraph: 20,
  mobileParagraphBold: 20,
  mobileParagraphExtraSmall: 14,
  mobileParagraphSmall: 18,
  mobileParagraphSmallBold: 18,
  mobileParagraphExtraSmallBold: 14,
  mobileLabel: 22,

  iconExtraSmall: 15,
  iconSmall: 18,
  iconMedium: 20,
  iconLarge: 29,
};

const paragraph = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: lineHeights.paragraph + 'px',
  letterSpacing: 0,
};

const paragraphBold = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphBold + 'px',
  letterSpacing: 0,
};

const paragraphLink = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphLink + 'px',
  letterSpacing: 0,
};

const paragraphSmall = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphSmall + 'px',
  letterSpacing: 0,
};

const paragraphLarge = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphLarge + 'px',
  letterSpacing: 0,
};

const paragraphSmallBold = {
  fontSize: 11,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphSmallBold + 'px',
  letterSpacing: 0,
};

const paragraphSmallLink = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphSmallLink + 'px',
  letterSpacing: 0,
};

const mobileParagraph = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: lineHeights.mobileParagraph + 'px',
  letterSpacing: 0,
};

const mobileParagraphBold = {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphBold + 'px',
  letterSpacing: 0,
};

const mobileParagraphSmall = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: lineHeights.mobileParagraphSmall + 'px',
  letterSpacing: 0,
};

const mobileParagraphSmallBold = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphSmallBold + 'px',
  letterSpacing: 0,
};

const mobileParagraphExtraSmall = {
  fontSize: 11,
  lineHeight: lineHeights.mobileParagraphExtraSmall + 'px',
  fontWeight: 400,
  letterSpacing: 0,
};

const mobileParagraphExtraSmallBold = {
  fontSize: 11,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphExtraSmallBold + 'px',
  letterSpacing: 0,
};

export function getThemeBase(palette: PaletteOptions): ThemeOptions {
  return {
    custom: {
      headerHeights: {
        ...headerHeights,
      },
      footerHeights: {
        ...footerHeights,
      },
      lineHeights,
      spacing,
    },
    spacing,
    palette,
    typography: {
      fontFamily: 'Source Sans Pro',
      fontWeightLight: 400,
      fontSize: 14,
      h1: {
        fontSize: 26,
        fontWeight: 700,
        lineHeight: lineHeights.h1 + 'px',
        letterSpacing: -0.5,
      },
      h2: {
        fontSize: 22,
        fontWeight: 700,
        lineHeight: lineHeights.h2 + 'px',
        letterSpacing: 0,
      },
      h3: {
        fontSize: 26,
        fontWeight: 700,
        lineHeight: lineHeights.h3 + 'px',
        letterSpacing: -0.5,
      },
      h4: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: lineHeights.h4 + 'px',
        letterSpacing: 0,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: lineHeights.h5 + 'px',
        letterSpacing: 0.5,
      },
      h6: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: lineHeights.h6 + 'px',
        letterSpacing: 0,
      },
      paragraph,
      paragraphBold,
      paragraphLink,
      paragraphLarge,
      paragraphSmall,
      paragraphSmallBold,
      paragraphSmallLink,
      numberedList: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: lineHeights.numberedList + 'px',
        letterSpacing: 0,
      },
      mobileParagraph,
      mobileParagraphBold,
      mobileParagraphExtraSmall,
      mobileParagraphExtraSmallBold,
      mobileParagraphSmall,
      mobileParagraphSmallBold,
      mobileLabel: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: lineHeights.mobileLabel + 'px',
        letterSpacing: 0,
      },
      iconExtraSmall: {
        fontSize: 10,
        lineHeight: lineHeights.iconExtraSmall + 'px',
      },
      iconSmall: {
        fontSize: 14,
        lineHeight: lineHeights.iconSmall + 'px',
      },
      iconMedium: {
        fontSize: 20,
        lineHeight: lineHeights.iconMedium + 'px',
      },
      iconLarge: {
        fontSize: 26,
        lineHeight: lineHeights.iconLarge + 'px',
      },

      body1: paragraph,
      body2: paragraphBold,
      subtitle1: paragraphSmall,
      subtitle2: paragraphSmallBold,
      button: {
        ...paragraphBold,
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 4,
    },
    breakpoints: breakPointsOptions,
  };
}
