import ApiService from './api-service';
import { ServiceResult } from '../models/index';
import { FileUpload, GetFileUploadQueryParams, GetFileUploadResponse } from '../models/file-upload.models';

class FileUploadService extends ApiService {
  private static classInstance: FileUploadService;

  private constructor() {
    super();
  }

  public getFileUploadList(
    vendorId: number,
    queryParams: GetFileUploadQueryParams
  ): ServiceResult<GetFileUploadResponse> {
    return this.get(`${this.baseUrl}/FileUpload/Vendor/${vendorId}`, queryParams);
  }

  public uploadFile(request: Uint8Array, vendorId: number, fileName: string): ServiceResult<FileUpload[]> {
    return this.post(`${this.baseUrl}/FileUpload/Vendor/${vendorId}/${fileName}`, request);
  }

  public getFileResult(fileId: string): ServiceResult<string> {
    return this.get(`${this.baseUrl}/FileUpload/${fileId}`);
  }

  public getProcessingFiles(vendorId: number): ServiceResult<FileUpload[]> {
    return this.get(`${this.baseUrl}/FileUpload/Progress/Vendor/${vendorId}`);
  }

  public static getInstance(): FileUploadService {
    if (!this.classInstance) {
      this.classInstance = new FileUploadService();
    }
    return this.classInstance;
  }
}

export default FileUploadService;
