import React from 'react';
import List, { ListItem } from './List';
import Drawer from './Drawer';

interface DrawerListProps {
  open: boolean;
  items: ListItem[];
  onItemClicked: () => void;
  selectedItemKey: number | string | undefined;
}

const DrawerList = (props: DrawerListProps): JSX.Element => {
  return (
    <Drawer open={props.open} onClickAway={props.onItemClicked}>
      <List items={props.items} onItemClicked={props.onItemClicked} selectedItemKey={props.selectedItemKey} />
    </Drawer>
  );
};

export default DrawerList;
