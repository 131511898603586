import ApiService from './api-service';
import { ServiceResult } from '../models/index';
import { Vendor } from '../models/global.models';

class VendorService extends ApiService {
  private static classInstance: VendorService;

  private constructor() {
    super();
  }

  public getVendorList(): ServiceResult<Vendor[]> {
    return this.get(`${this.baseUrl}/vendor`);
  }

  public static getInstance(): VendorService {
    if (!this.classInstance) {
      this.classInstance = new VendorService();
    }
    return this.classInstance;
  }
}

export default VendorService;
