import { AxiosInstance } from 'axios';
import { getAxiosInstance } from './axios-instance';
import { ServiceResult } from '../models/index';

abstract class ApiService {
  protected readonly axiosInstance: AxiosInstance;
  protected readonly baseUrl: string;

  protected constructor() {
    this.baseUrl = this.configureBaseUrl();
    this.axiosInstance = getAxiosInstance();
  }

  public get<T>(url: string, params?: unknown): ServiceResult<T> {
    return this.axiosInstance.get(url, { params });
  }

  public post<T>(url: string, body: unknown, requestType: 'body' | 'queryParams' = 'body'): ServiceResult<T> {
    return requestType === 'body'
      ? this.axiosInstance.post(url, body)
      : this.axiosInstance.post(url, {}, { params: body });
  }

  public put<T>(url: string, body: unknown, requestType: 'body' | 'queryParams' = 'body'): ServiceResult<T> {
    return requestType === 'body'
      ? this.axiosInstance.put(url, body)
      : this.axiosInstance.put(url, {}, { params: body });
  }

  public delete<T>(url: string): ServiceResult<T> {
    return this.axiosInstance.delete(url);
  }

  public configureBaseUrl(): string {
    return process.env.REACT_APP_API_BASE_URL + '/api';
  }
}

export default ApiService;
