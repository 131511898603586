import { Suspense, useEffect, useState } from 'react';
import { getTheme } from './theme/theme';
import '@mui/styles';
import { StyledEngineProvider, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import AppContent from './AppContent';
import { CustomGlobalStyles } from './CustomGlobalStyles';
import { CircularProgress } from '@mui/material';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { loginRequest } from './api/auth';
import { getVendors, useAppDispatch } from './store/index';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const auth = useMsalAuthentication(InteractionType.Redirect, { ...loginRequest });
  const isAuthenticated = useIsAuthenticated();

  const [theme, setTheme] = useState<Theme | undefined>(undefined);

  useEffect(() => {
    setTheme(getTheme());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getVendors());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (auth.error instanceof InteractionRequiredAuthError) {
      auth.login(InteractionType.Redirect, { ...loginRequest });
    }
  }, [auth.error]);

  return (
    <>
      {isAuthenticated && (
        <StylesProvider injectFirst>
          {theme && (
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={theme}>
                <CustomGlobalStyles />
                <Suspense fallback={<CircularProgress />}>
                  <AppContent />
                </Suspense>
              </MuiThemeProvider>
            </StyledEngineProvider>
          )}
        </StylesProvider>
      )}
    </>
  );
}

export default App;
