import { Breakpoint, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { createTheme, Theme } from '@mui/material/styles';
import { getPalette } from './palette';
import { getThemeBase } from './base.theme';
import { OverridableStringUnion } from '@mui/types';
import { getMuiThemeOptions } from './mui.theme';

export const getTheme = (): Theme => {
  const palette = getPalette();
  const themeBase = getThemeBase(palette);
  const muiThemeOptions = getMuiThemeOptions(themeBase);
  return createTheme(muiThemeOptions);
};

export type HeaderHeights = { [key in Breakpoint]: number };
export type FooterHeights = { [key in Breakpoint]: number };

export interface CustomThemeOptions {
  headerHeights: HeaderHeights;
  footerHeights: FooterHeights;
  lineHeights: { [key in OverridableStringUnion<Variant, TypographyPropsVariantOverrides>]: number };
  spacing: number;
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomThemeOptions;
  }
  interface ThemeOptions {
    custom: CustomThemeOptions;
  }
  interface Palette {
    coolGrey: Palette['grey'];
  }
  interface PaletteOptions {
    coolGrey: PaletteOptions['grey'];
  }
  //https://mui.com/customization/typography/#adding-amp-disabling-variants
  interface TypographyVariants {
    paragraph: React.CSSProperties;
    paragraphBold: React.CSSProperties;
    paragraphLink: React.CSSProperties;
    paragraphLarge: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    paragraphSmallBold: React.CSSProperties;
    paragraphSmallLink: React.CSSProperties;
    numberedList: React.CSSProperties;

    mobileParagraph: React.CSSProperties;
    mobileParagraphBold: React.CSSProperties;
    mobileParagraphExtraSmall: React.CSSProperties;
    mobileParagraphExtraSmallBold: React.CSSProperties;
    mobileParagraphSmall: React.CSSProperties;
    mobileParagraphSmallBold: React.CSSProperties;
    mobileLabel: React.CSSProperties;

    iconExtraSmall: React.CSSProperties;
    iconSmall: React.CSSProperties;
    iconMedium: React.CSSProperties;
    iconLarge: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    paragraph: React.CSSProperties;
    paragraphBold: React.CSSProperties;
    paragraphLink: React.CSSProperties;
    paragraphLarge: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    paragraphSmallBold: React.CSSProperties;
    paragraphSmallLink: React.CSSProperties;
    numberedList: React.CSSProperties;

    mobileParagraph: React.CSSProperties;
    mobileParagraphBold: React.CSSProperties;
    mobileParagraphExtraSmall: React.CSSProperties;
    mobileParagraphExtraSmallBold: React.CSSProperties;
    mobileParagraphSmall: React.CSSProperties;
    mobileParagraphSmallBold: React.CSSProperties;
    mobileLabel: React.CSSProperties;

    iconExtraSmall: React.CSSProperties;
    iconSmall: React.CSSProperties;
    iconMedium: React.CSSProperties;
    iconLarge: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;

    paragraph: true;
    paragraphBold: true;
    paragraphLink: true;
    paragraphLarge: true;
    paragraphSmall: true;
    paragraphSmallBold: true;
    paragraphSmallLink: true;
    numberedList: true;

    mobileParagraph: true;
    mobileParagraphBold: true;
    mobileParagraphSmall: true;
    mobileParagraphSmallBold: true;
    mobileParagraphExtraSmall: true;
    mobileParagraphExtraSmallBold: true;
    mobileLabel: true;

    iconExtraSmall: true;
    iconSmall: true;
    iconMedium: true;
    iconLarge: true;
  }
}
