import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes, RouteName } from '../models/routing.models';

const Home = lazy(() => import('../views/Home'));

export const AppRouting = (): JSX.Element => {
  return (
    <Switch>
      <Route path={AppRoutes[RouteName.Home].Path} component={Home} exact />
    </Switch>
  );
};
