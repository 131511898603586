import { AuthenticationResult, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as rax from 'retry-axios';
import { loginRequest, msalConfig } from '../auth';
import { ApiResponse, ServiceResult } from '../models/api-shared.models';
import { globalSlice, store } from '../../store/index';

let axiosInstance: AxiosInstance;
export const msalInstance = new PublicClientApplication(msalConfig);

export const getAxiosInstance = (): AxiosInstance => {
  if (!axiosInstance) {
    axiosInstance = axios.create();
    axios.defaults.raxConfig = {
      retry: 3,
      noResponseRetries: 3,
      httpMethodsToRetry: ['HEAD', 'OPTIONS', 'GET', 'POST', 'PUT', 'DELETE'],
      statusCodesToRetry: [[408], [429], [500], [502, 504]],
      backoffType: 'exponential',
      instance: axiosInstance,
      onRetryAttempt: (axiosError: AxiosError<unknown>) => {
        const retryConfig = rax.getConfig(axiosError);
        if (retryConfig?.currentRetryAttempt === retryConfig?.retry) {
          // [TODO]: Handle max retry error
        }
      },
    };

    // Only use Auth Interceptor & Re-Try Configuration in Non-Test Environments
    const isTestEnvironment = process.env.NODE_ENV === 'test';
    if (!isTestEnvironment) {
      axiosInstance.interceptors.request.use(bearerTokenInterceptor);
      rax.attach(axiosInstance);
    }
    axiosInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
  }
  return axiosInstance;
};

const responseSuccessInterceptor = async (response: AxiosResponse<ApiResponse<unknown>>): ServiceResult<unknown> => {
  if (!response.data.isSuccess) {
    store.dispatch(globalSlice.actions.setErrorMessages(response.data.errorMessages));
  }
  return response;
};

const responseErrorInterceptor = async (error: AxiosError<ApiResponse<unknown>>): ServiceResult<AxiosError> => {
  if (error.response) {
    const note = 'Please contact Metal Reader support.';
    if (error.response.data.errorMessages.length > 0) {
      store.dispatch(globalSlice.actions.setErrorMessages([...error.response.data.errorMessages, note]));
    } else {
      store.dispatch(globalSlice.actions.setErrorMessages([error.response.statusText, note]));
    }
  }
  return Promise.reject(error);
};

const bearerTokenInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const { accessToken } = await getAccessToken(loginRequest);
  request.headers.Accept = 'application/json';
  request.headers['Content-Type'] = 'application/json';
  if (accessToken) request.headers['Authorization'] = `Bearer ${accessToken}`;
  return request;
};

const getAccessToken = async (request: SilentRequest): Promise<AuthenticationResult> => {
  const accounts = msalInstance.getAllAccounts();
  const homeAccountId = accounts.length > 0 ? accounts[0]?.homeAccountId : '';
  const accountInfo = msalInstance.getAccountByHomeId(homeAccountId);
  request.account = accountInfo || undefined;

  return msalInstance
    .acquireTokenSilent(request)
    .then((response: AuthenticationResult) => {
      return response;
    })
    .catch((error) => {
      console.log('Silent token acquisition failed. Redirecting to login page. \n', error);
      console.log(error);
      return error;
    });
};
