export enum RouteName {
  Login = 'Login',
  Home = 'Home',
}

export type AppRoute = {
  DisplayName: string;
  Path: string;
  Params?: string[];
  QueryParams?: { [key: string]: string };
};

export const AppRoutes: Record<RouteName, AppRoute> = {
  [RouteName.Login]: { DisplayName: 'Login', Path: '/login' },
  [RouteName.Home]: { DisplayName: 'Home', Path: '/' },
};
