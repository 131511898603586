import { TypographyPropsVariantOverrides } from '@mui/material';
import { Theme, ThemeOptions } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';

export function getMuiThemeOptions(themeBaseOptions: ThemeOptions): ThemeOptions {
  const themeBase = themeBaseOptions as Theme;
  const spacing = themeBaseOptions.spacing as number;
  // type buttonSizes = { small: number; medium: number; large: number } & {
  //   [key in keyof ButtonPropsSizeOverrides]: number;
  // };
  // const buttonPadding: { X: buttonSizes; Y: number } = {
  //   X: {
  //     small: (spacing * 4 - themeBase.custom.lineHeights.paragraphBold) / 2,
  //     medium: (spacing * 5 - themeBase.custom.lineHeights.mobileParagraphBold) / 2,
  //     large: (spacing * 6 - themeBase.custom.lineHeights.mobileParagraphBold) / 2,
  //   },
  //   Y: spacing * 2,
  // };

  function generateInputRules(options: {
    typographyVariant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
    paddingX: number;
    paddingY: number;
    borderWidthFocus: number;
  }) {
    const lineHeight = themeBase.custom.lineHeights[options.typographyVariant];
    return {
      backgroundColor: themeBase.palette.common.white,
      borderRadius: themeBase.shape.borderRadius,
      height: lineHeight + options.paddingY * 2,
      input: {
        height: lineHeight,
      },
      '&:not(.MuiInputBase-formControl)': {
        input: {
          // ...(themeBase.typography[options.typographyVariant] as React.CSSProperties),
          // paddingTop: options.paddingY - options.borderWidthNormal,
          // paddingBottom: options.paddingY - options.borderWidthNormal,
          // paddingLeft: options.paddingX - options.borderWidthNormal,
          // paddingRight: options.paddingX - options.borderWidthNormal,

          // borderWidth: options.borderWidthNormal,
          borderStyle: 'solid',
          borderColor: themeBase.palette.coolGrey[300],
          borderRadius: themeBase.shape.borderRadius,

          '&:focus': {
            borderColor: themeBase.palette.primary.main,
            borderWidth: options.borderWidthFocus,

            paddingTop: options.paddingY - options.borderWidthFocus,
            paddingBottom: options.paddingY - options.borderWidthFocus,
            paddingLeft: options.paddingX - options.borderWidthFocus,
            paddingRight: options.paddingX - options.borderWidthFocus,
          },
          '&:disabled': {
            borderColor: themeBase.palette.coolGrey[300],
          },
        },
      },
    };
  }

  return {
    ...themeBaseOptions,
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'paragraphSmallLink' },
            style: {
              textDecoration: 'underline',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
          {
            props: { variant: 'paragraphLink' },
            style: {
              textDecoration: 'underline',
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        ],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 2 * Number(themeBase.shape.borderRadius),
          },
          outlined: {
            borderColor: themeBase.palette.coolGrey[200],
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            overflow: 'unset',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            ':last-child': {
              paddingBottom: spacing * 2,
            },
          },
        },
      },

      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            color: themeBase.palette.common.black,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: themeBase.palette.coolGrey[300],
            '.MuiSvgIcon-root > path': {
              border: `1px solid ${themeBase.palette.coolGrey[300]}`,
              borderRadius: '4px',
            },
            '&.MuiCheckbox-colorPrimary.Mui-checked.Mui-disabled .MuiIcon-root': {
              color: themeBase.palette.common.white,
              borderColor: themeBase.palette.coolGrey[300],
              backgroundColor: themeBase.palette.coolGrey[300],
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-sizeSmall	': {
              height: '32px',
            },
          },
        },
      },
      MuiIcon: {
        defaultProps: {
          baseClassName: 'fas',
          fontSize: 'inherit',
        },
        styleOverrides: {
          root: {
            boxSizing: 'content-box',
            fontSize: 'inherit',
            verticalAlign: 'middle',
            textAlign: 'center',
            minWidth: '1em',
            minHeight: '1em',
            width: 'auto',
            height: 'auto',
            overflow: 'unset',
            '&.MuiIcon-root.MuiIcon-fontSizeExtraSmall': {
              ...themeBase.typography.iconExtraSmall,
              letterSpacing: '1px',
            },
            '&.MuiIcon-root.MuiIcon-fontSizeSmall': {
              ...themeBase.typography.iconSmall,
              letterSpacing: '1px',
            },
            '&.MuiIcon-root.MuiIcon-fontSizeMedium': {
              ...themeBase.typography.iconMedium,
            },
            '&.MuiIcon-root.MuiIcon-fontSizeLarge': {
              ...themeBase.typography.iconLarge,
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: 'primary',
        },
        styleOverrides: {
          root: {
            '&:hover': {
              color: themeBase.palette.action.focus,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            color: themeBase.palette.grey[400],
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'small',
          color: 'primary',
        },
        styleOverrides: {
          root: {
            '&.MuiButton-root': {
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor: themeBase.palette.action.focus,
            },
          },
          outlined: {
            // borderWidth: themeBase.custom.outlinedButtonBorderWidth,
            borderStyle: 'solid',
            // paddingLeft: buttonPadding.Y - themeBase.custom.outlinedButtonBorderWidth,
            // paddingRight: buttonPadding.Y - themeBase.custom.outlinedButtonBorderWidth,
            '&.MuiButton-sizeSmall': {
              height: 32,
              ...themeBase.typography.paragraphBold,
              // paddingTop: buttonPadding.X.small - themeBase.custom.outlinedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.small - themeBase.custom.outlinedButtonBorderWidth,
            },
            '&.MuiButton-sizeMedium': {
              height: 40,
              ...themeBase.typography.mobileParagraphBold,
              // paddingTop: buttonPadding.X.medium - themeBase.custom.outlinedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.medium - themeBase.custom.outlinedButtonBorderWidth,
            },
            '&.MuiButton-sizeLarge': {
              ...themeBase.typography.mobileParagraphBold,
              // paddingTop: buttonPadding.X.large - themeBase.custom.outlinedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.large - themeBase.custom.outlinedButtonBorderWidth,
            },
            '&.MuiButton-outlinedPrimary': {
              borderColor: themeBase.palette.primary.main,
              '&:hover': {
                borderColor: themeBase.palette.primary.dark,
                // borderWidth: themeBase.custom.outlinedButtonBorderWidth,
              },
              '&:focus': {
                borderColor: themeBase.palette.action.focus,
                // boxShadow: `inset 0px 0px 0px ${2 - themeBase.custom.outlinedButtonBorderWidth}px ${
                //   themeBase.palette.action.focus
                // }`,
              },
            },
          },
          contained: {
            // borderWidth: themeBase.custom.containedButtonBorderWidth,
            borderStyle: 'solid',
            // paddingLeft: buttonPadding.Y - themeBase.custom.containedButtonBorderWidth,
            // paddingRight: buttonPadding.Y - themeBase.custom.containedButtonBorderWidth,
            '&.MuiButton-sizeSmall': {
              height: 32,
              ...themeBase.typography.paragraphBold,
              // paddingTop: buttonPadding.X.small - themeBase.custom.containedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.small - themeBase.custom.containedButtonBorderWidth,
            },
            '&.MuiButton-sizeMedium': {
              height: 40,
              ...themeBase.typography.mobileParagraphBold,
              // paddingTop: buttonPadding.X.medium - themeBase.custom.containedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.medium - themeBase.custom.containedButtonBorderWidth,
            },
            '&.MuiButton-sizeLarge': {
              ...themeBase.typography.mobileParagraphBold,
              // paddingTop: buttonPadding.X.large - themeBase.custom.containedButtonBorderWidth,
              // paddingBottom: buttonPadding.X.large - themeBase.custom.containedButtonBorderWidth,
            },
            '&.MuiButton-containedPrimary': {
              borderColor: themeBase.palette.primary.main,
              '&:hover': {
                borderColor: themeBase.palette.primary.dark,
              },
              '&:focus': {
                borderColor: themeBase.palette.action.focus,
              },
              '&.Mui-disabled': {
                color: themeBase.palette.coolGrey[100],
                borderColor: themeBase.palette.coolGrey[400],
                backgroundColor: themeBase.palette.coolGrey[400],
              },
            },
            '&.MuiButton-containedSecondary': {
              borderColor: themeBase.palette.secondary.main,
              '&:hover': {
                borderColor: themeBase.palette.secondary.dark,
              },
              '&:focus': {
                borderColor: themeBase.palette.coolGrey[500],
              },
              '&.Mui-disabled': {
                color: themeBase.palette.common.white,
                borderColor: themeBase.palette.coolGrey[300],
                backgroundColor: themeBase.palette.coolGrey[300],
              },
            },
          },
          text: {
            '&.MuiButton-text': {
              '&:focus': {
                color: themeBase.palette?.common?.black,
                border: 'none',
              },
              '&:hover': {
                backgroundColor: 'unset',
              },
            },
          },
          startIcon: {
            '&.MuiButton-startIcon': {
              marginLeft: '0',
              marginRight: '8px',
            },
          },
          endIcon: {
            '&.MuiButton-endIcon': {
              marginLeft: '8px',
              marginRight: '0',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.MuiToggleButton-sizeSmall': {
              paddingLeft: spacing / 2,
              paddingRight: spacing / 2,
              paddingTop: (spacing * 3.25 - themeBase.custom.lineHeights.iconMedium) / 2,
              paddingBottom: (spacing * 3.25 - themeBase.custom.lineHeights.iconMedium) / 2,
            },
          },
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            '&.MuiInputBase-sizeExtraSmall': {
              ...generateInputRules({
                typographyVariant: 'paragraphSmall',
                paddingX: spacing,
                paddingY: spacing * 0.5,
                // borderWidthNormal: themeBase.custom.outlinedButtonBorderWidth,
                borderWidthFocus: 2,
              }),
            },

            '&.MuiInputBase-sizeSmall': {
              ...generateInputRules({
                typographyVariant: 'paragraph',
                paddingX: spacing,
                paddingY: spacing * 0.5,
                // borderWidthNormal: themeBase.custom.outlinedButtonBorderWidth,
                borderWidthFocus: 2,
              }),
            },
            '&.MuiInputBase-sizeMedium': {
              ...generateInputRules({
                typographyVariant: 'paragraph',
                paddingX: spacing * 2,
                paddingY: spacing,
                // borderWidthNormal: themeBase.custom.outlinedButtonBorderWidth,
                borderWidthFocus: 2,
              }),
            },
            '&.MuiInputBase-sizeLarge': {
              ...generateInputRules({
                typographyVariant: 'paragraph',
                paddingX: spacing * 1.5,
                paddingY: spacing * 1.25,
                // borderWidthNormal: themeBase.custom.outlinedButtonBorderWidth,
                borderWidthFocus: 2,
              }),
            },
            '&.MuiInputBase-sizeExtraLarge': {
              ...generateInputRules({
                typographyVariant: 'mobileParagraph',
                paddingX: spacing * 1.5,
                paddingY: spacing * 1.25,
                // borderWidthNormal: themeBase.custom.outlinedButtonBorderWidth,
                borderWidthFocus: 2,
              }),
            },
            '&.Mui-disabled': {
              backgroundColor: themeBase.palette.coolGrey[100],
              color: themeBase.palette.coolGrey[500],
            },
          },
          input: {
            '&::placeholder': {
              color: themeBase.palette.coolGrey[700],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': {
              height: '24px',
            },
            '&.MuiInputBase-root.MuiInput-sizeExtraSmall': {
              ...themeBase.typography.paragraphSmall,
              height: '24px',
            },
            '&.MuiInputBase-root.MuiInputBase-sizeSmall': {
              ...themeBase.typography.paragraph,
              height: '32px',
            },
            '&.MuiInputBase-root.MuiInputBase-sizeMedium': {
              ...themeBase.typography.paragraph,
              height: '40px',
            },
            '&.MuiInputBase-root.MuiInputBase-sizeLarge': {
              fontSize: '16px',
              lineHeight: '20px',
              height: '40px',
            },
          },
          input: {
            '&::placeholder': {
              color: themeBase.palette.coolGrey[700],
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': {
              height: '36px',
            },
            '&.MuiInputBase-root.MuiInputBase-sizeSmall': {
              height: '27px',
            },
          },
          input: {
            '&::placeholder': {
              color: themeBase.palette.coolGrey[700],
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.MuiRadio-root.Mui-checked > .MuiBox-root > span': {
              borderColor: themeBase.palette.primary.main,
            },
            '&.MuiRadio-root > .MuiBox-root > span': {
              borderColor: themeBase.palette.coolGrey[300],
            },
            '&.MuiRadio-root.Mui-focusVisible > .MuiBox-root > span': {
              borderColor: themeBase.palette.primary.main,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            '&.MuiSwitch-root.MuiSwitch-sizeMedium': {
              width: '56px',
              height: '32px',
              padding: '10px',
              '& .MuiSwitch-switchBase': {
                padding: '6px',
                '&.Mui-checked': {
                  transform: 'translateX(24px)',
                },
              },
            },
          },
          switchBase: {
            '&.Mui-disabled': {
              color: themeBase.palette?.coolGrey?.[200],
            },
            '&.MuiSwitch-colorSecondary.Mui-checked': {
              color: themeBase.palette?.coolGrey?.[300],
            },
          },
          thumb: {
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
          },
          track: {
            backgroundColor: `${themeBase.palette?.coolGrey?.[200]} !important`,
            opacity: '1 !important',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            borderCollapse: 'separate',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.MuiTableRow-root:first-of-type > td': {
              borderTop: 'none',
            },
            '&.MuiTableRow-root.CfTableRow-custom > td, &.MuiTableRow-root.CfTableRow-footer > td': {
              borderTop: 'none',
            },
            '.MuiTableRow-root.CfTableRow-custom + &.MuiTableRow-root > td': {
              borderTop: 'none',
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: themeBase.palette.primary.main,
            borderRadius: 8,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            ...(themeBase.typography.h5 as React.CSSProperties),
            padding: spacing,
            color: themeBase.palette.common.white,
            textTransform: 'uppercase',
          },
          body: {
            ...(themeBase.typography.paragraph as React.CSSProperties),
            padding: spacing,
            borderBottom: 'none',
            borderTop: '1px solid ' + themeBase.palette.coolGrey[200],
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '0px',
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          arrow: {
            '&.MuiTooltip-arrow': {
              color: themeBase.palette.coolGrey[600],
            },
          },
          tooltip: {
            '&.MuiTooltip-tooltip': {
              ...themeBase.typography.paragraphSmall,
              background: themeBase.palette.coolGrey[600],
              borderRadius: '4px',
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '8px',
              paddingBottom: '8px',
              maxWidth: '600px',
              margin: '6px !important',
            },
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          rectangular: {
            borderRadius: themeBase.shape.borderRadius + 'px',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            lineHeight: '16px',
            color: themeBase.palette.error.main,
            marginLeft: 0,
          },
        },
      },
    },
    custom: {
      ...themeBaseOptions.custom,
    },
  };
}
