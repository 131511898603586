import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Logo } from '../_shared';
import { Hamburger } from './components/index';
import DrawerMenu from '../DrawerList';
import { RootState, setSelectedVendor, useAppDispatch, useAppSelector } from '../../store/index';
import { ListItem } from '../List';
import { useHistory } from 'react-router-dom';
import { AppRoutes, RouteName } from '../../models/routing.models';

const Header = (): JSX.Element => {
  const { breakpoint } = useBreakpoint();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const vendors = useAppSelector((s: RootState) => s.global.vendors);
  const selectedVendor = useAppSelector((s: RootState) => s.global.selectedVendor);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [vendorOptions, setVendorOptions] = useState<ListItem[]>([]);

  useEffect(() => {
    const _vendors: ListItem[] = vendors.map((v) => {
      return {
        key: v.vendorId,
        label: v.vendorName || '',
        onClickItem: () => dispatch(setSelectedVendor(v)),
        icon: <img src={v.vendorImage} />,
      };
    });
    setVendorOptions(_vendors);
  }, [vendors]);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: (theme) => theme.custom.headerHeights[breakpoint],
        px: 3,
        alignItems: 'center',
        display: 'flex',
        borderBottom: '1px solid black',
        boxSizing: 'border-box',
        zIndex: 102,
        mb: 3,
        top: 0,
        backgroundColor: (theme) => theme.palette.common.white,
        position: 'sticky',
      }}
    >
      <Hamburger onClick={toggleDrawer} />
      <Logo onClick={() => history.push(AppRoutes[RouteName.Home].Path)} />
      <Typography variant="h2" color="primary">
        Metal Reader
      </Typography>
      <DrawerMenu
        items={vendorOptions}
        open={drawerOpen}
        onItemClicked={closeDrawer}
        selectedItemKey={selectedVendor?.vendorId}
      />
    </Box>
  );
};

export default Header;
