import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

interface HamburgerProps {
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const Hamburger = (props: HamburgerProps): JSX.Element => {
  return (
    <IconButton aria-label="menu" onClick={props.onClick} sx={{ mr: 3, zIndex: 102 }}>
      <MenuIcon />
    </IconButton>
  );
};
