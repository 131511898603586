import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RootState, toggleErrorDialog, useAppDispatch, useAppSelector } from '../../store/index';
import { Typography } from '@mui/material';

export const AlertDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const open = useAppSelector((s: RootState) => s.global.errorDialogOpen);
  const errorMessages = useAppSelector((s: RootState) => s.global.errorMessages);

  return (
    <Dialog open={open}>
      <DialogTitle variant="h2">Error!</DialogTitle>
      <DialogContent>
        <DialogContentText variant="paragraph" sx={{ color: (theme) => theme.palette.text.primary }}>
          {errorMessages.map((err, idx) => {
            return (
              <Typography key={`error-${idx}`} sx={{ mb: 1 }}>
                {err}
              </Typography>
            );
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={() => dispatch(toggleErrorDialog(false))} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
