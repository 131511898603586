import React from 'react';
import logo from '../../assets/images/logo.png';
import { styled } from '@mui/styles';

interface LogoProps {
  onClick?: () => void;
}

export const Logo = (props: LogoProps): JSX.Element => {
  return <StyledImg src={logo} alt="Ryerson logo" onClick={props.onClick} />;
};

export const StyledImg = styled('img')(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));
