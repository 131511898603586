import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vendor } from '../../api/models/global.models';

interface GlobalState {
  networkError: boolean;
  vendors: Vendor[];
  selectedVendor: Vendor | undefined;
  errorDialogOpen: boolean;
  errorMessages: string[];
}

const initialState: GlobalState = {
  networkError: false,
  vendors: [],
  selectedVendor: undefined,
  errorDialogOpen: false,
  errorMessages: [],
};

// Reducers
export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setNetworkError: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.networkError = action.payload;
    },
    setVendors: (state: GlobalState, action: PayloadAction<Vendor[]>) => {
      state.vendors = action.payload;
    },
    setSelectedVendor: (state: GlobalState, action: PayloadAction<Vendor | undefined>) => {
      state.selectedVendor = action.payload;
    },
    setErrorDialogOpen: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload;
    },
    setErrorMessages: (state: GlobalState, action: PayloadAction<string[]>) => {
      state.errorMessages = action.payload;
      state.errorDialogOpen = true;
    },
  },
});
