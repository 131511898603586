import React from 'react';
import {
  ListItemIcon,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
} from '@mui/material';

export interface ListItem {
  key: string | number;
  label: string;
  onClickItem: () => void;
  icon?: React.ReactNode;
}

interface ListProps {
  items: ListItem[];
  onItemClicked: () => void;
  sx?: SxProps<Theme>;
  selectedItemKey: number | string | undefined;
}

const List = (props: ListProps): JSX.Element => {
  const handleClickItem = (item: ListItem) => () => {
    props.onItemClicked();
    item.onClickItem();
  };

  return (
    <MuiList sx={{ backgroundColor: (theme) => theme.palette.common.white, height: '100%' }}>
      {props.items.map((item) => (
        <MuiListItem key={item.key} disablePadding onClick={handleClickItem(item)}>
          <ListItemButton selected={props.items.some((i) => i.key === props.selectedItemKey)}>
            <ListItemIcon sx={{ height: 30 }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'h2' }} />
          </ListItemButton>
        </MuiListItem>
      ))}
    </MuiList>
  );
};

export default List;
