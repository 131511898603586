import { css, GlobalStyles } from '@mui/material';
import { useTheme } from '@mui/styles';

export const CustomGlobalStyles = (): JSX.Element => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={css`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        body {
          font-family: ${theme.typography.fontFamily};
          position: relative;
          min-height: 100vh;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow-y: overlay;
          padding: 0 !important;
        }

        input[type='number'] {
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}
    />
  );
};
