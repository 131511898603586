import { AppDispatch, AppThunk, RootState } from '../store';
import FileUploadService from '../../api/services/file-upload.service';
import { filesSlice } from './files.slice';
import { globalSlice } from './global.slice';

const fileUploadService = FileUploadService.getInstance();

export const getFiles =
  (_vendorId?: number): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const state = getState();
      if (!state.files.filesLoading) dispatch(filesSlice.actions.setFilesLoading(true));
      const vendorId = _vendorId || state.global.selectedVendor?.vendorId;

      if (!vendorId) return;
      const { data } = await fileUploadService.getFileUploadList(vendorId, {
        page: state.files.page,
        pageSize: state.files.rowsPerPage,
      });
      if (data.isSuccess)
        dispatch(
          filesSlice.actions.setFiles({ files: data.resultObject.content, count: data.resultObject.totalCount || 0 })
        );
      else {
        // TODO: Handle if IsSuccess is false
      }
    } catch (ex) {
      console.error('Error occurred in file upload thunks!');
      console.error(ex);
    } finally {
      dispatch(filesSlice.actions.setFilesLoading(false));
    }
  };

export const uploadFile =
  (fileArray: Uint8Array, file: File): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const state = getState();
      const selectedVendor = state.global.selectedVendor;

      if (!selectedVendor) return;

      if (file.type === 'text/csv') {
        const { data } = await fileUploadService.uploadFile(fileArray, selectedVendor.vendorId, file.name);
        if (data.isSuccess) {
          dispatch(getFiles());
        } else {
          // TODO: Handle if IsSuccess is false
        }
      } else {
        dispatch(globalSlice.actions.setErrorMessages(['Uploaded file must be a CSV!']));
      }
    } catch (ex) {
      console.error('Error occurred in file upload thunks!');
      console.error(ex);
    }
  };

export const downloadFile =
  (fileId: string, fieldName: string | undefined, callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await fileUploadService.getFileResult(fileId);
      if (data.isSuccess) {
        const blob = new Blob([Buffer.from(data.resultObject, 'base64')], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Processed_${fieldName}.csv`;
        link.click();
      } else {
        dispatch(globalSlice.actions.setErrorMessages(data.errorMessages));
      }
    } catch (ex) {
      console.error('Error occurred in file download thunks!');
      console.error(ex);
    } finally {
      callBack?.();
    }
  };

export const getProcessingFiles = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const state = getState();
    const vendorId = state.global.selectedVendor?.vendorId;
    const files = [...state.files.files];

    if (!vendorId) return;

    const { data } = await fileUploadService.getProcessingFiles(vendorId);

    if (data.isSuccess) {
      data.resultObject.forEach((newFile) => {
        const idx = files.findIndex((oldFile) => oldFile.fileUploadId === newFile.fileUploadId);
        files[idx] = {
          ...files[idx],
          processStatus: newFile.processStatus,
          completedDate: newFile.completedDate,
          progressValue: newFile.progressValue,
        };
      });

      dispatch(filesSlice.actions.setFiles({ files: files }));
    } else {
      dispatch(globalSlice.actions.setErrorMessages(data.errorMessages));
    }
  } catch (ex) {
    console.error('Error occurred in file upload thunks!');
    console.error(ex);
  }
};

export const resetPaging = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(filesSlice.actions.resetPaging());
};

export const setPage =
  (newPage: number, callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(filesSlice.actions.setPage(newPage + 1));
    callBack?.();
  };

export const setRowsPerPage =
  (rpp: number, callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(filesSlice.actions.setRowsPerPage(rpp));
    callBack?.();
  };
