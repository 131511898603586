import Header from './components/Header/Header';
import Footer from './components/Footer';
import { StyledPageContainer } from './App.styles';
import { AppRouting } from './routing/AppRouting';
import { Box } from '@mui/material';
import React from 'react';
import AlertDialog from './components/_shared/ErrorDialog';

const AppContent = (): JSX.Element => {
  return (
    <Box sx={{ height: '100vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <StyledPageContainer>
        <AppRouting />
      </StyledPageContainer>
      <Footer />
      <AlertDialog />
    </Box>
  );
};

export default AppContent;
