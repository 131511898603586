import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileUpload } from '../../api/models/file-upload.models';

interface FilesState {
  files: FileUpload[];
  fileCount: number;
  filesLoading: boolean;
  uploadedFile: string;
  page: number;
  rowsPerPage: number;
}

const initialState: FilesState = {
  files: [],
  fileCount: 0,
  filesLoading: true,
  uploadedFile: '',
  page: 1,
  rowsPerPage: 25,
};

// Reducers
export const filesSlice = createSlice({
  name: 'files',
  initialState: initialState,
  reducers: {
    setFiles: (state: FilesState, action: PayloadAction<{ files: FileUpload[]; count?: number }>) => {
      state.files = action.payload.files;
      if (action.payload.count) state.fileCount = action.payload.count;
    },
    setFilesLoading: (state: FilesState, action: PayloadAction<boolean>) => {
      state.filesLoading = action.payload;
    },
    setPage: (state: FilesState, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state: FilesState, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
    },
    resetPaging: (state: FilesState) => {
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
    },
  },
});
