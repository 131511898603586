import React, { PropsWithChildren } from 'react';
import { Box, ClickAwayListener, Slide, SxProps, Theme } from '@mui/material';
import Overlay from './Overlay';

interface DrawerProps {
  open: boolean;
  onClickAway?: () => void;
  sx?: SxProps<Theme>;
}

const Drawer = (props: PropsWithChildren<DrawerProps>): JSX.Element => {
  const handleClickAway = () => {
    props.onClickAway?.();
  };

  return (
    <>
      {props.open && <Overlay />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Slide direction="right" in={props.open} timeout={500}>
          <Box
            sx={{
              width: { xs: '80%', xl: '25%' },
              backgroundColor: (theme) => theme.palette.background.default,
              height: '100%',
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 102,
              ...props.sx,
            }}
          >
            {props.children}
          </Box>
        </Slide>
      </ClickAwayListener>
    </>
  );
};

export default Drawer;
