import { AppDispatch, AppThunk } from '../store';
import VendorService from '../../api/services/vendor.service';
import { globalSlice } from './global.slice';
import { Vendor } from '../../api/models/global.models';
import { getFiles } from './files.thunks';
import { filesSlice } from './files.slice';

const vendorService = VendorService.getInstance();

export const getVendors = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const { data } = await vendorService.getVendorList();
    if (data.isSuccess) {
      const vendors = data.resultObject;
      dispatch(globalSlice.actions.setVendors(data.resultObject));
      if (vendors.length > 0) dispatch(setSelectedVendor(data.resultObject[0]));
    }
  } catch (ex) {
    console.error('Error occurred in global thunks!');
    console.error(ex);
  }
};

export const setSelectedVendor =
  (vendor: Vendor | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(globalSlice.actions.setSelectedVendor(vendor));
      dispatch(filesSlice.actions.resetPaging());
      dispatch(getFiles(vendor?.vendorId));
    } catch (ex) {
      console.error('Error occurred in global thunks!');
      console.error(ex);
    }
  };

export const toggleErrorDialog =
  (val: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.setErrorDialogOpen(val));
  };
